import styled from "@emotion/styled"
import { graphql } from "gatsby"
import camelCase from "lodash/camelCase"
import { useState } from "react"
import Layout from "../components/Layout"
import MemberCard from "../components/MemberCard"
import MemberList from "../components/MemberList"
import NoRecord from "../components/NoRecord"
import Pagination from "../components/Pagination"
import RecordListItem from "../components/RecordListItem"
import SEO from "../components/Seo"
import TabBody from "../components/TabBody"
import TabHeader from "../components/TabHeader"
import { getTitle } from "../utils/getTitle"

const Container = styled.div`
  margin: 2rem auto 5rem auto;
  padding: 2rem;
  min-height: 20vh;
`

export default ({ pageContext, data }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const { langKey } = pageContext
  const category = "Corporate Governance"
  const title = getTitle(langKey, category)
  const _langKey = camelCase(langKey)
  const { records, members } = data
  const perPage = 10

  const indexOfLastItem = currentPage * perPage
  const indexOfFirstItem = indexOfLastItem - perPage
  const isFirst = currentPage === 1
  const isLast = currentPage === Math.ceil(records.edges.length / perPage)
  const currentItems = records.edges.slice(indexOfFirstItem, indexOfLastItem)

  return (
    <Layout fluid langKey={langKey}>
      <SEO title={title} />

      <MemberList>
        {members.edges.map(({ node }, i) => (
          <MemberCard
            key={i}
            name={node.title[_langKey]}
            members={node.member}
            langKey={_langKey}
          />
        ))}
      </MemberList>

      <Container>
        <TabHeader title={title} />
        <TabBody>
          {currentItems.length > 0 ? (
            currentItems.map(({ node }, i) => (
              <RecordListItem
                key={`record-${i}`}
                {...{
                  ...node,
                  langKey: _langKey,
                  isLast: records.totalCount === i + 1,
                }}
              />
            ))
          ) : (
            <NoRecord langKey={langKey} />
          )}
        </TabBody>

        <Pagination
          {...{
            langKey,
            perPage,
            isFirst,
            isLast,
            currentPage,
            setCurrentPage,
            noRecord: records.edges.length === 0,
            totalCount: records.totalCount,
          }}
        />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query CorporateGovernanceEnPageQuery {
    members: allSanityMember {
      totalCount
      edges {
        node {
          title {
            en
            zhHant
          }
          member {
            en
            zhHant
          }
        }
      }
    }

    records: allSanityRecord(
      filter: { category: { name: { en: { eq: "Corporate Governance" } } } }
      sort: { fields: date, order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          name {
            zhHant
            en
          }
          date
          file {
            en {
              asset {
                url
              }
            }
            zhHant {
              asset {
                url
              }
            }
          }
        }
      }
    }
  }
`
